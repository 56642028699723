<!-- @/shared/components/dynamic/blocks/ArticleBlock.vue -->
<template>
    <div class="w-full flex flex-col gap-4 md:gap-5 py-2">
        <v-button
            v-if="editMode?.active"
            class="self-start"
            @click.stop="emit('connect-summary', 1)"
        >
            Connect first summary
        </v-button>
        <template v-for="(summary,index) in summaries" :key="summary.id">
            <summary-block
                is="div"
                :user-summary-id="summary.id"
                :user-summary-content="summary.text"
                v-if="summary.type === 'paragraphs'"
                class="relative w-full flex flex-col gap-4"
            >
                <p
                    v-for="(paragraph, index) in splitTextIntoParagraphs(summary.text)"
                    :key="index"
                    class=""
                >{{ paragraph }}</p>
                <button
                    v-if="editMode?.active"
                    class="absolute right-0 top-0 rounded-full bg-red-500 p-1 text-white"
                    @click.stop="emit('delete', summary.id)"
                >
                    <trash-icon class="h-4 w-4" />
                </button>
            </summary-block>
            <v-button
                v-if="editMode?.active"
                class="self-start"
                @click.stop="emit('connect-summary', index + 2)"
            >
                Connect summary here
            </v-button>
        </template>
    </div>
</template>

<script lang="ts" setup>
import { SummaryComponentSummary } from "@/shared/types/page";
import VButton from '@/shared/components/VButton.vue'
import { editModeInjection } from '@/shared/types/injection'
import { inject } from 'vue'
import TrashIcon from '@/shared/components/icons/TrashIcon.vue'
import SummaryBlock from '@/shared/components/SummaryBlock.vue'

const emit = defineEmits(['connect-summary', 'delete'])

const props = defineProps({
    summaries: {
        type: Array<SummaryComponentSummary>,
        default: () => [],
    },
});

const editMode = inject(editModeInjection);

const splitTextIntoParagraphs = (text: string): string[] => {
    return text ? text.split('\n').filter(paragraph => paragraph.trim() !== '') : [];
};
</script>

<style scoped></style>
