<template>
    <div
        v-if="
            (tabs?.filter((tab) =>
                tab.enabled !== undefined
                    ? tab.enabled === true || tab.enabled.value
                    : true
            )?.length > 1) || loading
        "
        :class="menuWrapperClass"
    >
        <div
            ref="container"
            :class="menuContainerClass"
        >
            <template
                v-for="(tab, index) in loading
                    ? Array.from({ length: 3 })
                    : tabs"
                :key="tab?.template ?? 'loading-' + index"
            >
                <div
                    v-if="loading"
                    class="animate-skeleton bg-stone-800 rounded-lg border-none px-3 py-2 text-base"
                ></div>
                <slot
                    v-else-if="
                        tab &&
                        (tab.enabled !== undefined
                            ? tab.enabled === true || tab.enabled.value
                            : true)
                    "
                    :active="activeTab == tab.template"
                    :select="() => handleTabClick(tab.template)"
                    :tab="tab"
                >
                    <button
                        :class="[
                            activeTab == tab?.template
                                ? menuBtnSelectedClass
                                : menuBtnClass,
                            mainBtnClass,
                        ]"
                        :data-key="tab?.template ?? 'loading-' + index"
                        @click="handleTabClick(tab.template)"
                    >
                        <slot :name="tab?.template + '-title'">
                            {{ tab?.title }}
                        </slot>
                    </button>
                </slot>
            </template>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { inject } from 'vue'
import {
    tabLayoutActiveTabInjection,
    tabLayoutTabsInjection,
} from '@/shared/types/injection'
import { useRouter } from 'vue-router'

const props = defineProps({
    loading: {
        type: Boolean,
        default: false,
    },
    menuWrapperClass: {
        type: String,
        default: 'mx-auto w-full max-w-full overflow-x-auto px-2',
    },
    menuContainerClass: {
        type: String,
        default: 'z-10 flex w-full flex-nowrap pt-5',
    },
    mainBtnClass: {
        type: String,
        default:
            'select-none whitespace-nowrap px-4 pb-2 text-base font-medium',
    },
    menuBtnSelectedClass: {
        type: String,
        default: 'border-b border-stone-950 text-stone-950',
    },
    menuBtnClass: {
        type: String,
        default: 'border-b border-stone-200 text-stone-400',
    },
})
const tabs = inject(tabLayoutTabsInjection)
const activeTab = inject(tabLayoutActiveTabInjection)
const router = useRouter()

function handleTabClick(template: string) {
    activeTab!.value = template
    // if (props.scrollToPageSection) {
    //     const tabContent = comp.value?.querySelector(`#${template}`)
    //     if (tabContent) {
    //         window.scrollTo({
    //             top: tabContent.offsetTop - header.value?.clientHeight,
    //             behavior: 'smooth',
    //         })
    //     }
    // } else {
    //     activeTab.value = template
    // }
}
</script>

<style scoped></style>
