<template>
    <router-link
        :class="{
            'cursor-default': disableLink,
        }"
        :disabled="disableLink"
        :style="`
            background: linear-gradient(
                    0deg,
                    ${hexToRgba(card.color)} 0%,
                    ${hexToRgba(card.color)} 100%
                ),
                #fff;
                color: ${card?.color};`"
        :to="{
            name: route.params.project_slug ? 'project-dynamic-conversation' : 'dynamic-conversation',
            params: routeParams,
            query: routeQuery,
        }"
        class="flex w-full cursor-pointer select-none items-start gap-4 rounded-lg border border-solid border-transparent p-4 duration-75 hover:scale-101 hover:border-inherit"
    >
        <achievement-progress-circle
            :color="card.color"
            :icon="card?.icon?.thumbnail"
            :percent="progress ?? card.percent"
            :time="(card?.time / 60).toFixed(0) + ' min'"
        />
        <div class="flex w-full flex-col items-start gap-0">
            <div class="flex w-full flex-col gap-0">
                <!--                <div-->
                <!--                    v-if="card?.target_type"-->
                <!--                    :style="`color: ${card?.color};`"-->
                <!--                    class="flex w-full text-3xs uppercase font-bold leading-tight"-->
                <!--                >-->
                <!--                    {{ card.target_type }}-->
                <!--                </div>-->
                <div
                    v-if="card?.details"
                    :style="`color: ${card?.color};`"
                    class="flex flex-row gap-2 items-center w-full text-3xs font-bold uppercase leading-tight"
                >
                    <div>
                        {{ card.target_type }}
                    </div>
                    <template
                        v-for="(detail, index) in card.details"
                        :key="index"
                    >
                        <span
                            class="text-black opacity-50"
                        >{{ detail }}</span>
                        <point-icon v-if="index !== card.details.length - 1" />
                    </template>
                </div>
                <h5
                    class="my-0 py-0 text-lg font-semibold text-black opacity-90"
                >
                    {{ card.title }}
                </h5>
            </div>
            <!--            <div-->
            <!--                v-if="card?.details"-->
            <!--                :style="`color: ${card?.color};`"-->
            <!--                class="flex items-center gap-1 text-xs font-semibold"-->
            <!--            >-->
            <!--                <template-->
            <!--                    v-for="(detail, index) in card.details"-->
            <!--                    :key="index"-->
            <!--                >-->
            <!--                    <span>{{ detail }}</span>-->
            <!--                    <point-icon v-if="index !== card.details.length - 1" />-->
            <!--                </template>-->

            <!--                &lt;!&ndash;                <point-icon v-if="card?.subtitle && card?.story" />&ndash;&gt;-->
            <!--                &lt;!&ndash;                {{ card?.story }}&ndash;&gt;-->
            <!--            </div>-->
            <div
                v-if="expanded"
                class="text-base font-normal text-black opacity-80"
            >
                {{ card.description }}
            </div>
        </div>
    </router-link>
</template>

<script lang="ts" setup>
import PointIcon from '@/shared/components/icons/PointIcon.vue'
import AchievementProgressCircle from '@/shared/components/AchievementProgressCircle.vue'
import { hexToRgba } from '@/shared/utils/colorUtils'
import { computed, PropType } from 'vue'
import { Achievement } from '@/shared/types/achievement'
import { useProjectStore } from '@/shared/stores/project'
import { useRoute } from 'vue-router'

const props = defineProps({
    card: {
        type: Object as PropType<Achievement>,
        required: true,
    },
    showStoryName: { type: Boolean, default: true },
    disableLink: { type: Boolean, default: false },
    progress: { type: Number, default: () => undefined },
    expanded: {
        type: Boolean,
        default: true,
    },
    project: {
        type: String,
        default: ()=>undefined,
    },
})

const route = useRoute()

const routeParams = computed(() => {
    switch (props.card?.target_type) {
        case 'User':
            return {
                endpoint: 'writer',
            }
        case 'Story':
            return {
                endpoint: 'stories',
                element: props.card.target_id,
            }
        case 'Character':
            return {
                endpoint: 'characters',
                element: props.card.target_id,
            }
        case 'Plot':
            return {
                endpoint: 'plots',
                element: props.card.target_id,
            }
        case 'Sequence':
            return {
                endpoint: 'sequences',
                element: props.card.target_id,
            }
        case 'Theme':
            return {
                endpoint: 'themes',
                element: props.card.target_id,
            }
        case 'ReferenceStory':
            return {
                endpoint: 'reference-stories',
                element: props.card.target_id,
            }
        case 'Audience':
            return {
                endpoint: 'audiences',
                element: props.card.target_id,
            }
        case 'Setting':
            return {
                endpoint: 'settings',
                element: props.card.target_id,
            }
        default:
            throw new Error('Unknown target type ' + props.card?.target_type)
    }
})

const routeQuery = computed(() => {
    return {
        achievement: props.card.id,
    }
})
</script>

<style scoped></style>
