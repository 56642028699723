<template>
    <div class="w-full flex flex-col">
        <div class="flex w-full flex-col gap-3 bg-stone-50 px-4 py-4 border-b border-solid border-stone-200">
            <div v-if="weekSummary" class="self-center w-full max-w-lg flex flex-row justify-between overflow-x-auto">
                <div
                    v-for="day in weekSummary"
                    :key="day.date"
                    class="flex flex-col items-center font-semibold text-xs gap-2 p-1 rounded-full cursor-pointer select-none"
                    @click="selectDay(day)"
                    :class="{
                        'bg-stone-800 text-stone-100': selectedDay && selectedDay.date === day.date,
                    }"
                >
                    <div
                        class="flex items-center justify-center w-8 h-8 md:w-10 md:h-10 flex-shrink-0 rounded-full text-sm"
                        :class="{
                            'bg-orange-gradient text-white': hasDevelopment(day) && !isToday(day.date)
                        }"
                    >{{ day.display_date }}</div>
                    <div
                        class="pb-2 md:pb-3 font-medium"
                        :class="{
                            'text-stone-200': selectedDay && selectedDay.date === day.date,
                            'text-stone-600': !selectedDay || selectedDay.date !== day.date,
                        }"
                    >
                        {{ day.weekday }}
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="selectedDay"
            class="dayStats w-full bg-white text-stone-800 border-b border-solid border-stone-100 px-4 py-2.5 select-none"
        >
            <div class="w-full flex flex-row gap-8 text-stone-500 justify-center items-center text-xs overflow-x-auto">
                <div v-if="selectedDay.element_types.length === 0" class="font-semibold text-stone-400">
                    {{ getNoDevelopmentMessage }}
                </div>
                <template v-else>
                    <div class="font-bold">Developed {{ getRelativeDayName }}</div>
                    <div
                        v-for="elementType in selectedDay.element_types"
                        :key="elementType.type"
                        class="cursor-pointer hover:text-stone-900"
                        @click="navigateToElementList(elementType)"
                    >
                        {{ elementType.count }} {{ elementType.display_name }}
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { getWeekSummary } from '@/shared/utils/endpoints'
import { useRouter } from 'vue-router'

const router = useRouter()
const weekSummary = ref(null)
const selectedDay = ref(null)

onMounted(async () => {
    await fetchWeekSummary()
})

const fetchWeekSummary = async () => {
    try {
        const summary = await getWeekSummary()
        weekSummary.value = summary
        // Optionally, select the current day by default
        selectDay(summary.find(day => day.date === new Date().toISOString().split('T')[0]))
    } catch (error) {
        console.error('Error fetching week summary:', error)
    }
}

const selectDay = (day) => {
    selectedDay.value = day
}

const navigateToElementList = (elementType) => {
    if (!selectedDay.value) return;

    router.push({
        path: elementType.path,
        query: { development_date: selectedDay.value.date }
    });
}

const getRelativeDayName = computed(() => {
    if (!selectedDay.value) return ''
    const date = new Date(selectedDay.value.date + 'T00:00:00Z')
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const diffTime = date.getTime() - today.getTime()
    const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24))

    if (diffDays === 0) return 'Today'
    if (diffDays === -1) return 'Yesterday'
    if (diffDays === 1) return 'Tomorrow'
    return 'on ' + date.toLocaleDateString('en-US', { weekday: 'short', timeZone: 'UTC' })
})

const getNoDevelopmentMessage = computed(() => {
    if (!selectedDay.value || !selectedDay.value.date) return ''

    const selectedDate = new Date(selectedDay.value.date + 'T00:00:00Z')
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const diffTime = selectedDate.getTime() - today.getTime()
    const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24))

    if (diffDays === 0) {
        return 'Start developing today'
    }

    if (diffDays === -1) {
        return 'No development yesterday'
    }

    if (diffDays === 1) {
        return 'No development scheduled for tomorrow'
    }

    return `No development ${getRelativeDayName.value}`
})

const hasDevelopment = (day) => {
    return day.element_types?.length > 0
}

const isToday = (dateString) => {
    const date = new Date(dateString + 'T00:00:00Z')
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return date.getTime() === today.getTime()
}
</script>

<style scoped>
</style>
