<template>
    <div
        class="flex flex-col items-center justify-center gap-5 backdrop-blur-sm"
        @click.self="emit('close')"
    >
        <div
            class="flex w-full max-w-[300px] flex-col gap-2 rounded-xl border bg-white px-2 py-2 text-lg shadow-xl md:px-4 md:py-4 lg:px-10 lg:py-8"
        >
            <h1>
                {{ title }}
            </h1>
            <p class="text-base">
                {{ message }}
            </p>
            <div class="flex justify-between gap-3">
                <v-button
                    ref="cancelButton"
                    @click="emit('close')"
                >
                    Cancel
                </v-button>
                <v-button
                    variant="danger"
                    @click="
                        () => {
                            emit('confirm')
                            emit('close')
                        }
                    "
                >
                    Confirm
                </v-button>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import VButton from '@/shared/components/VButton.vue'
import { onMounted, ref } from 'vue'

const emit = defineEmits(['close', 'confirm'])

const props = defineProps({
    title: String,
    message: String,
})

const cancelButton = ref<InstanceType<typeof VButton> | null>(null)

onMounted(() => {
    cancelButton.value?.$el.focus()
})
</script>

<style scoped></style>
