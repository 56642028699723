<template>
    <header
        class="flex w-full items-center gap-2 px-4 pb-5 pt-8 lg:hidden"
        style="
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%),
                linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.3) 0%,
                    rgba(0, 0, 0, 0.3) 100%
                ),
                #404040;
        "
    >
        <chevron-icon
            class="h-4 w-4 cursor-pointer"
            @click="goBack"
        />
        <section
            v-if="detailedInfo"
            class="flex w-full items-center gap-2"
        >
            <img
                :src="data.image.path"
                class="h-[44px] w-[44px] rounded-lg backdrop-grayscale-[50%]"
            />

            <div class="flex flex-col gap-1">
                <h2 class="font-fjalla text-xl text-stone-50">
                    {{ data.title }}
                </h2>
                <p
                    class="flex w-full items-center gap-2 text-3xs uppercase text-stone-400"
                >
                    {{ data.story }}
                    <span
                        v-if="data.episode"
                        class="flex items-center gap-2"
                    >
                        <point-icon />
                        {{ data.episode }}
                    </span>
                </p>
            </div>

            <flame-in-progress-circle
                :progress="data.progress"
                class="ml-auto mr-0 shrink-0 bg-white"
            />
        </section>
    </header>
</template>

<script setup lang="ts">
import PointIcon from '@/shared/components/icons/PointIcon.vue'
import ChevronIcon from '@/shared/components/icons/ChevronIcon.vue'
import FlameInProgressCircle from '@/shared/components/FlameInProgressCircle.vue'

const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
    detailedInfo: {
        type: Boolean,
        default: false,
    },
})

const goBack = () => {
    window.history.back()
}
</script>

<style scoped></style>
