<template>
    <svg
        viewBox="0 0 85 88"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="expandContract" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M18.1829268,0 C19.8731707,0 21,1.05781339 21,2.61934745 C21,4.28162564 19.8731707,5.33943904 18.1829268,5.33943904 L18.1829268,5.33943904 L6.60731707,5.33943904 L6.60731707,82.710933 L18.1829268,82.710933 C19.8731707,82.710933 21,83.7183744 21,85.3302805 C21,86.9421866 19.8731707,88 18.1829268,88 L18.1829268,88 L4.45609756,88 C1.79268293,88 0,86.2369777 0,83.6176302 L0,83.6176302 L0,4.43274184 C0,1.76302232 1.79268293,0 4.45609756,0 L4.45609756,0 Z M80.5843521,0 C83.2542787,0 85,1.76302232 85,4.43274184 L85,4.43274184 L85,83.6176302 C85,86.2369777 83.2542787,88 80.5843521,88 L80.5843521,88 L66.8239609,88 C65.1295844,88 64,86.9421866 64,85.3302805 C64,83.7183744 65.1295844,82.710933 66.8239609,82.710933 L66.8239609,82.710933 L78.4278729,82.710933 L78.4278729,5.33943904 L66.8239609,5.33943904 C65.1295844,5.33943904 64,4.28162564 64,2.61934745 C64,1.05781339 65.1295844,0 66.8239609,0 L66.8239609,0 Z M43.248,9.44 L53.248,17.44 C54.108,18.132 54.248,19.388 53.56,20.252 C53.168,20.744 52.584,21 52,21 C51.564,21 51.116,20.86 50.748,20.564 L43.9991686,15.164 L43.9991686,74.843 L50.752,69.436 C51.62,68.748 52.872,68.884 53.564,69.748 C54.252,70.616 54.112,71.868 53.252,72.56 L43.252,80.56 C42.884,80.852 42.44,81 42,81 C41.56,81 41.116,80.852 40.748,80.564 L30.748,72.564 C29.888,71.876 29.748,70.62 30.436,69.752 C31.128,68.892 32.384,68.752 33.248,69.44 L39.9991686,74.843 L39.9991686,15.164 L33.248,20.564 C32.38,21.256 31.128,21.112 30.436,20.252 C29.748,19.388 29.888,18.132 30.748,17.44 L40.748,9.44 C41.48,8.856 42.516,8.856 43.248,9.44 Z"
                  id="Combined-Shape"
                  fill="currentColor"
                  fill-rule="nonzero"></path>
        </g>
    </svg>
</template>
