<!-- ListFilter.vue -->
<template>
    <div class="flex flex-col px-1">
        <h6 class="text-base font-semibold text-stone-800">Filter by</h6>

        <!-- Status Filter -->
        <template v-if="showStatusFilter">
            <span class="mt-4 text-sm font-semibold text-stone-500">
                Status
            </span>
            <div class="mt-3 flex flex-col gap-3">
                <list-option
                    v-for="(option, optionID) in statusOptions"
                    :key="optionID"
                    :status="selectedOptions.includes(option)"
                    textClass="text-base"
                    class="w-full !px-4 !py-3"
                    multiple
                    @click="toggleOption(option)"
                >
                    <template #text>
                        {{ option }} ({{ getCount(option) }})
                    </template>
                </list-option>
            </div>
        </template>

        <!-- Readiness Filter -->
        <template v-if="showReadinessFilter">
            <span class="mt-4 text-sm font-semibold text-stone-500">
                Readiness
            </span>
            <div class="mt-3 flex gap-2">
                <button
                    :class="
                        selectedOptions.includes('all')
                            ? 'bg-stone-800 text-stone-50'
                            : 'bg-stone-100 text-stone-500'
                    "
                    class="flex w-full max-w-14 flex-col items-center gap-2 rounded-lg p-2 text-sm font-bold"
                    @click="toggleOption('all')"
                >
                    All
                    <span class="text-xs font-normal">{{ totalCount }}</span>
                </button>
                <button
                    v-for="(option, optionID) in readinessOptions"
                    :key="optionID"
                    :class="
                        selectedOptions.includes(option)
                            ? 'bg-stone-800 text-stone-50'
                            : 'bg-stone-100 text-stone-500'
                    "
                    class="flex w-full max-w-14 flex-col items-center gap-2 rounded-lg p-2 text-xs"
                    @click="toggleOption(option)"
                >
                    <flame-icon
                        :progress="getProgressForOption(option)"
                        class="!h-6 !w-6 !bg-white"
                    />
                    {{ getCount(option) }}
                </button>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import ListOption from '@/shared/components/ListOption.vue'
import FlameIcon from '@/shared/components/FlameInProgressCircle.vue'

const emit = defineEmits(['update:selectedOptions'])

const props = defineProps({
    showStatusFilter: { type: Boolean, default: false },
    showReadinessFilter: { type: Boolean, default: false },
    statusOptions: {
        type: Array,
        default: () => ['Earned', 'In progress', 'Not Started'],
    },
    readinessOptions: { type: Array, default: () => [4, 3, 2, 1] },
    items: {
        type: Array,
        required: true,
        default: () => []
    },
    getItemProgress: {
        type: Function,
        required: true,
        default: (item: any) => 0 // Provide a default implementation
    },
    getItemStatus: { type: Function, required: true },
})

const selectedOptions = ref(['all'])

const toggleOption = (option) => {
    if (option === 'all') {
        selectedOptions.value = ['all']
    } else {
        const index = selectedOptions.value.indexOf(option)
        if (index === -1) {
            selectedOptions.value.push(option)
            selectedOptions.value = selectedOptions.value.filter(
                (opt) => opt !== 'all'
            )
        } else {
            selectedOptions.value.splice(index, 1)
        }
        if (selectedOptions.value.length === 0) {
            selectedOptions.value = ['all']
        }
    }
    emit('update:selectedOptions', selectedOptions.value)
}

const getCount = (option) => {
    if (!Array.isArray(props.items)) return 0
    if (option === 'all') return props.items.length
    return props.items.filter((item) =>
        props.showStatusFilter
            ? props.getItemStatus(item) === option
            : props.getItemProgress(item) === getProgressForOption(option)
    ).length
}

const getProgressForOption = (option) => {
    if (option === 4) return 0
    if (option === 3) return 31
    if (option === 2) return 61
    if (option === 1) return 100
    return 0
}

const totalCount = computed(() => Array.isArray(props.items) ? props.items.length : 0)
</script>
