<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
    >
        <path
            d="M5.84847 7.99903C5.84847 7.99903 7.2049 4.1913 8.25613 3.57261C9.30736 2.95392 10.0082 3.92302 10.0082 4.62385C10.0082 5.32467 9.52736 7.50905 9.52736 7.50905L13.369 7.02884C14.3408 6.90737 15.1512 7.76406 14.976 8.72765L14.1364 13.3458C13.9939 14.1296 13.246 14.6518 12.4611 14.5155L5.84847 13.3674M5.84847 7.99903V13.3674M5.84847 7.99903H4.13939C3.8372 7.99903 3.5474 8.11907 3.33372 8.33275C3.12004 8.54642 3 8.83623 3 9.13841V12.228C3 12.5302 3.12004 12.82 3.33372 13.0336C3.5474 13.2473 3.8372 13.3674 4.13939 13.3674H5.84847"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
