<!-- /components/TabLayout.vue -->
<template>
    <div
        ref="comp"
        class="flex w-full grow flex-col items-stretch"
    >
        <slot />
    </div>
</template>

<script lang="ts" setup>
import {
    computed,
    onMounted,
    onUnmounted,
    PropType,
    provide,
    ref,
    watch,
} from 'vue'
import { Tab } from '@/shared/types/layout'
import {
    tabLayoutActiveTabInjection,
    tabLayoutTabsInjection,
} from '@/shared/types/injection'
import { useRoute, useRouter } from 'vue-router'

const props = defineProps({
    tabs: {
        type: Array as PropType<Tab[]>,
        required: true,
    },
    scrollToPageSection: {
        type: Boolean,
        default: false,
    },
    menuBtnClass: {
        type: String,
        default: 'border-neutral-300 text-neutral-500',
    },
    menuBtnSelectedClass: {
        type: String,
        default: 'border-red-600 text-red-600',
    },
    menuContainerClass: {
        type: String,
        default: 'z-10 flex w-full flex-nowrap px-4 ',
    },
    menuWrapperClass: {
        type: String,
        default: 'mx-auto max-w-full overflow-x-auto',
    },
    tabsContentClass: {
        type: String,
        default: 'flex flex-col gap-8 w-full',
    },
    tabContentClass: {
        type: String,
        default: '',
    },
    tabsOnePage: {
        type: Boolean,
        default: false,
    },
    headerHeight: {
        type: [Number, String],
        default: 400,
    },
    collapseHeaderHeight: {
        type: [Number, String],
        default: 100,
    },
})

const route = useRoute()
const router = useRouter()
const activeTab = ref(
    route.query.tab ?? props.tabs?.filter((tab) => tab.active)[0]?.template
)
const container = ref<HTMLDivElement | undefined>(undefined)
const comp = ref<HTMLDivElement | undefined>(undefined)

provide(
    tabLayoutTabsInjection,
    computed(() => props.tabs)
)
provide(tabLayoutActiveTabInjection, activeTab)
provide('headerHeight', props.headerHeight)
provide('collapseHeaderHeight', props.collapseHeaderHeight)

watch(
    () => route.query.tab,
    (newTab) => {
        if (newTab && typeof newTab === 'string') {
            activeTab.value = newTab
        }
    }
)

watch(
    () => activeTab.value,
    (newValue) => {
        if (!props.scrollToPageSection && container.value) {
            const tab = container.value.querySelector(
                `[data-key="${newValue}"]`
            ) as HTMLButtonElement
            if (tab) {
                tab.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'center',
                })
            }
        }

        // Update URL when active tab changes
        router.replace({ query: { ...route.query, tab: newValue } })
    }
)

watch(
    () => props.tabs,
    (newValue) => {
        if (!newValue.find((tab) => tab.template === activeTab.value)) {
            activeTab.value = newValue[0]?.template
        }
    }
)

function handleTabClick(template: string) {
    if (props.scrollToPageSection) {
        const tabContent = comp.value?.querySelector(`#${template}`)
        if (tabContent) {
            window.scrollTo({
                top: tabContent.offsetTop - header.value?.clientHeight,
                behavior: 'smooth',
            })
        }
    } else {
        activeTab.value = template
    }
}

function onScroll(event: Event) {
    const sections = comp.value?.querySelectorAll('.scroll-section')
    if (!sections) return
    const activeSection = Array.from(sections).find((section) => {
        const sectionTop =
            section.getBoundingClientRect().top - header.value?.clientHeight
        return sectionTop <= 0 && sectionTop + section.clientHeight > 0
    }) as HTMLElement
    if (activeSection) {
        activeTab.value = activeSection.id
    }
}

onMounted(() => {
    if (props.scrollToPageSection) {
        document.addEventListener('scroll', onScroll)
    }
})

onUnmounted(() => {
    if (props.scrollToPageSection) {
        document.removeEventListener('scroll', onScroll)
    }
})
</script>

<style scoped></style>
