import { defineStore } from 'pinia'
import {
    getProject,
    getProjectElements,
    getProjects,
} from '@/shared/utils/endpoints'
import { useQuery } from '@tanstack/vue-query'
import { computed, ref, Ref } from 'vue'

async function fetchProject(slug: string, isTest: boolean = false) {
    return await getProject(slug, isTest)
}

async function fetchMostRecentProject() {
    try {
        const projects = await getProjects()
        if (projects.length > 0) {
            const mostRecent = projects[0] // Assuming projects are sorted by recency
            currentProject.value = mostRecent
            return mostRecent
        }
        return null
    } catch (error) {
        console.error('Error fetching most recent project:', error)
        return null
    }
}

async function fetchProjectElements(
    slug: string,
    categoryModelPlural: string
) {
    return await getProjectElements(slug, categoryModelPlural)
}

export const useProjectStore = defineStore('project', () => {
    // return { currentProject, fetchProject, fetchMostRecentProject }
})

export function useProject(projectSlug: string) {
    return useQuery({
        queryKey: ['project', projectSlug],
        queryFn: () => fetchProject(projectSlug),
    })
}
export function useProjectElements(projectSlug:string, categoryModelPlural: Ref<string|null>) {
    return useQuery({
        queryKey: ['projectElements', projectSlug, categoryModelPlural],
        queryFn: () => fetchProjectElements(projectSlug, categoryModelPlural.value),
        enabled: computed(() => !!categoryModelPlural.value),
    })
}
