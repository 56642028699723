<template>
    <div class="flex w-full flex-col items-center">
        <tab-layout
            :tabs="[
                { title: 'Continue', template: 'continue' },
                // { title: 'Progress', template: 'progress' },
                //{ title: 'Clarify', template: 'clarify' },
                //{ title: 'New', template: 'new' },
                // { title: 'Transcripts', template: 'transcripts' },
            ]"
            tabs-content-class="w-full flex flex-col gap-2 bg-slate-100"
        >
            <home-header />
            <div class="sticky top-0 z-10 bg-white">
                <tab-layout-tabs />
            </div>
            <tab-layout-view
                class="w-full pb-6"
                one-line
            >
                <template #continue>
                    <title-section
                        class="flex w-full flex-col items-center !gap-6 bg-white py-6"
                    >
                        <template #title>
                            <div class="flex w-full max-w-prose text-base">
                                <title-with-link
                                    :to="{
                                        name: 'stories',
                                    }"
                                >
                                    <h4
                                        class="w-full text-lg font-bold text-black"
                                    >
                                        Recent Stories
                                    </h4>
                                </title-with-link>
                            </div>
                        </template>
                        <horizontal-list
                            :items="stories"
                            class="flex w-full max-w-prose items-center text-base"
                        >
                            <template #new>
                                <new-story-card class="w-full shrink-0" />
                            </template>
                            <template #item="{ item }">
                                <recent-story-card :story="item" />
                            </template>
                        </horizontal-list>
                    </title-section>
                    <template v-if="!isLoading">
                        <dynamic-section
                            v-for="section in userAchievementsList"
                            :key="'section-' + section.slug"
                            :section="section"
                        />
                    </template>
                    <div
                        v-else
                        class="flex w-full items-center justify-center bg-white pb-10 pt-3"
                    >
                        <app-preloader />
                    </div>
                    <!--                    <title-section class="py-6">-->
                    <!--                        <template #title>-->
                    <!--                            <title-with-link>-->
                    <!--                                <h4 class="text-lg font-bold text-black">-->
                    <!--                                    Continue where you left off-->
                    <!--                                </h4>-->
                    <!--                            </title-with-link>-->
                    <!--                        </template>-->
                    <!--                        <div class="flex w-full flex-col gap-6 px-4">-->
                    <!--                            <achievement-in-progress-card-->
                    <!--                                v-for="(achievement, achievementID) in user-->
                    <!--                                    ?.achievements?.continue"-->
                    <!--                                :key="achievementID"-->
                    <!--                                :card="achievement"-->
                    <!--                                route-name="home"-->
                    <!--                                class="w-full"-->
                    <!--                            />-->
                    <!--                        </div>-->
                    <!--                    </title-section>-->
                </template>
                <template #clarify>
                    <!--                    <title-section-->
                    <!--                        v-if="false"-->
                    <!--                        class="!gap-6 px-4 py-6"-->
                    <!--                        style="-->
                    <!--                            background: linear-gradient(-->
                    <!--                                    180deg,-->
                    <!--                                    rgba(0, 0, 0, 0) 0%,-->
                    <!--                                    #000 100%-->
                    <!--                                ),-->
                    <!--                                linear-gradient(-->
                    <!--                                    0deg,-->
                    <!--                                    rgba(0, 0, 0, 0.3) 0%,-->
                    <!--                                    rgba(0, 0, 0, 0.3) 100%-->
                    <!--                                ),-->
                    <!--                                linear-gradient(0deg, #404040 0%, #404040 100%),-->
                    <!--                                #fff;-->
                    <!--                        "-->
                    <!--                    >-->
                    <!--                        <template #title>-->
                    <!--                            <title-with-link-->
                    <!--                                class="!p-0"-->
                    <!--                                title="Clarify discrepancies"-->
                    <!--                                title-class="text-lg text-white font-bold"-->
                    <!--                            />-->
                    <!--                        </template>-->

                    <!--                        <discrepancies-card-->
                    <!--                            v-for="(card, cardID) in data.discrepancies"-->
                    <!--                            :key="cardID"-->
                    <!--                            :card="card"-->
                    <!--                        />-->
                    <!--                    </title-section>-->
                </template>

                <template #new>
                    <!--                    <title-section class="!gap-6 px-4 py-6">-->
                    <!--                        <template #title>-->
                    <!--                            <title-with-link-->
                    <!--                                class="!p-0"-->
                    <!--                                title="Starting Something New"-->
                    <!--                                title-class="text-lg text-black font-bold"-->
                    <!--                            />-->
                    <!--                            <div-->
                    <!--                                class="flex w-full max-w-full items-center gap-3 overflow-scroll"-->
                    <!--                            >-->
                    <!--                                <div-->
                    <!--                                    v-for="(item, itemID) in createNew"-->
                    <!--                                    :key="itemID"-->
                    <!--                                    class="flex w-full flex-col items-center gap-1 text-center"-->
                    <!--                                >-->
                    <!--                                    <div-->
                    <!--                                        class="flex h-14 w-14 shrink-0 items-center justify-center rounded-full bg-stone-100 font-normal"-->
                    <!--                                    >-->
                    <!--                                        <component-->
                    <!--                                            :is="item?.icon"-->
                    <!--                                            class="text-stone-400"-->
                    <!--                                        />-->
                    <!--                                    </div>-->
                    <!--                                    <span-->
                    <!--                                        class="text-xs font-semibold text-stone-500"-->
                    <!--                                    >-->
                    <!--                                        {{ item.title }}-->
                    <!--                                    </span>-->
                    <!--                                </div>-->
                    <!--                            </div>-->
                    <!--                            <hr class="mx-auto my-1 w-48 text-stone-300" />-->
                    <!--                            &lt;!&ndash;                            <achievement-in-progress-card&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                                v-for="(&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                                    achievement, achievementID&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                                ) in data.achievements_in_progress"&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                                :key="achievementID"&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                                :card="achievement"&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                                route-name="home"&ndash;&gt;-->
                    <!--                            &lt;!&ndash;                            />&ndash;&gt;-->
                    <!--                        </template>-->
                    <!--                    </title-section>-->
                </template>
                <template #transcripts>
                    <!--                    <home-statistic-component-->
                    <!--                        :statistic="data.statistic"-->
                    <!--                        class="!py-6"-->
                    <!--                    />-->
                </template>
            </tab-layout-view>
        </tab-layout>
        <!--        <div-->
        <!--            v-if="showDiscuss"-->
        <!--            class="fixed bottom-0 left-0 right-0 px-4 pb-3"-->
        <!--        >-->
        <!--            <discuss-component @close="showDiscuss = false" />-->
        <!--        </div>-->
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
// import { useAuthStore } from '@/stores/auth'
import AppPreloader from '@/shared/components/cards/AppPreloader.vue'

import SeriesIcon from '@/shared/components/icons/SeriesIcon.vue'
import BookWithPlus from '@/shared/components/icons/BookWithPlus.vue'
import UserWithPlus from '@/shared/components/icons/UserWithPlus.vue'
import SequencesWithPlus from '@/shared/components/icons/SequencesWithPlus.vue'

import TabLayout from '@/shared/components/TabLayout.vue'
import TabLayoutTabs from '@/shared/components/ui/TabLayoutTabs.vue'
import TabLayoutView from '@/shared/components/ui/TabLayoutView.vue'

import TitleWithLink from '@/shared/components/TitleWithLink.vue'
import TitleSection from '@/shared/components/TitleSection.vue'
import RecentStoryCard from '@/shared/components/cards/RecentStoryCard.vue'
import NewStoryCard from '@/shared/components/cards/NewStoryCard.vue'
import HomeHeader from '@/app/views/HomeHeader.vue'
import { useStories } from '@/shared/composables/query/story'
import HorizontalList from '@/shared/components/ui/HorizontalList.vue'
import { useUser } from '@/shared/composables/query/user'
import DynamicSection from '@/shared/components/dynamic/DynamicSection.vue'
import { getUserAchievements } from '@/shared/utils/endpoints'
import { useQuery } from '@tanstack/vue-query'

const showDiscuss = ref(true)

const { data: stories } = useStories()
const { data: user } = useUser()

const {
    data: userAchievements,
    isLoading,
    error,
} = useQuery({
    queryKey: ['userAchievements'],
    queryFn: getUserAchievements,
})

const userAchievementsList = computed(() => userAchievements?.value?.data ?? [])

const createNew = [
    { icon: SeriesIcon, title: 'Series' },
    { icon: BookWithPlus, title: 'Story' },
    { icon: UserWithPlus, title: 'Character' },
    { icon: SequencesWithPlus, title: 'Sequence' },
]
</script>
