<!-- Namespace: shared.components.cards -->
<!-- Filename: ReconciliationCard.vue -->
<template>
    <div
        :class="
            isEditMode
                ? ''
                : 'rounded-xl border border-solid border-stone-200 bg-stone-50'
        "
        class="flex flex-col gap-0 self-stretch"
    >
        <div
            class="flex w-full gap-2 border-b border-solid border-b-stone-200 p-3 font-bold"
        >
            <div
                class="flex w-full flex-1 shrink basis-0 flex-col justify-center select-none"
            >
                <div
                    v-if="
                        element?.target_type_display_name ||
                        element?.user_summary_name
                    "
                    class="w-full flex-1 shrink gap-1 self-stretch text-ellipsis text-4xs uppercase text-stone-500"
                >
                    {{ element.target_type_display_name }}
                    <template
                        v-if="
                            element?.target_type_display_name &&
                            element?.user_summary_name
                        "
                    >
                        •
                    </template>
                    {{ element.user_summary_name }}
                </div>
                <div
                    v-if="element?.target_name"
                    class="text-ellipsis text-base text-stone-800"
                >
                    {{ element.target_name }}
                </div>
            </div>
            <div class="flex flex-row items-center gap-3">
                <div
                    v-if="!canEdit"
                    class="select-none p-1"
                    @click="onEditIconClick"
                >
                    <edit-icon
                        class="h-5 w-5 shrink-0 cursor-pointer text-stone-300 hover:text-stone-700"
                    />
                </div>
                <div
                    v-if="!canEdit"
                    class="select-none p-1"
                    @click="onDeleteIconClick"
                >
                    <delete-icon
                        class="h-5 w-5 shrink-0 cursor-pointer text-stone-300 hover:text-stone-700"
                    />
                </div>
            </div>
        </div>
        <div class="w-full flex flex-col p-3">
            <div
                v-if="!isEditMode"
                class="text-ellipsis line-clamp-3 w-full text-sm leading-5 text-stone-700"
            >
                {{ element.content }}
            </div>
            <div
                v-else-if="element?.content"
                class="w-full flex flex-col gap-4"
            >
                <dynamic-textarea
                    v-model="localTextInput"
                    :placeholder="'Enter your update here.'"
                    :max-length="6000"
                    :readonly="isProcessing"
                    :custom-class="'h-auto cursor-text bg-stone-100 w-full text-ellipsis text-base text-stone-700 focus:outline-none rounded-lg p-3'"
                    @update:model-value="onInput"
                    @click="onTextAreaClick"
                    @extract-data="extractData"
                    @limit-reached="onLimitReached"
                />

                <div
                    v-if="isEditMode"
                    class="flex w-full items-center justify-between gap-6"
                >
                    <button
                        class="w-full text-center select-none px-3 py-2 text-sm font-medium text-stone-400 hover:text-stone-700 cursor-pointer transition-opacity duration-200"
                        :class="{ 'opacity-50 cursor-not-allowed': !hasChanges || isProcessing }"
                        @click="cancelEdit"
                        :disabled="!hasChanges || isProcessing"
                    >
                        Cancel
                    </button>
                    <button
                        class="w-full text-center select-none rounded px-3 py-2 text-sm font-medium text-stone-50 transition-all duration-200"
                        :class="{
                            'opacity-50 cursor-not-allowed': !hasChanges || isProcessing,
                            'bg-stone-700 hover:bg-stone-800': !hasChanges,
                            'bg-blue-600 hover:bg-blue-700': hasChanges && !isProcessing
                        }"
                        @click="saveChanges"
                        :disabled="!hasChanges || isProcessing"
                    >
                        <span v-if="!isProcessing">Save Changes</span>
                        <span v-else class="flex items-center justify-center">
                            <svg class="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            Processing...
                        </span>
                    </button>
                </div>
                <div v-if="statusMessage" :class="{'text-green-600': isSuccess, 'text-red-600': !isSuccess}" class="text-center text-sm mt-2">
                    {{ statusMessage }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    ref,
    watch,
    onMounted,
    nextTick,
    computed,
    PropType,
} from 'vue';
import useModal from '@/shared/composables/useModal';
import EditIcon from '@/shared/components/icons/EditIcon.vue';
import DeleteIcon from '@/shared/components/icons/DeleteIcon.vue';
import DynamicTextarea from "@/shared/components/forms/DynamicTextarea.vue";
import { useUpdateReconciliationMutation, useDeleteReconciliationMutation } from "@/shared/stores/reconciliations";

const emit = defineEmits([
    'update:isEditMode',
    'update:canEdit',
    'update:element',
]);

const props = defineProps({
    element: {
        type: Object as PropType<CardData>,
        required: true,
    },
    isEditMode: {
        type: Boolean,
        default: false,
    },
    canEdit: {
        type: Boolean,
        default: false,
    },
    reconciliationSlug: {
        type: String,
        required: true,
    },
});

interface CardData {
    slug: string;
    reconciliation_update_slug: string;
    target_type_display_name?: string;
    user_summary_name?: string;
    target_name?: string;
    content?: string;
}

const modal = useModal();
const localTextInput = ref('');
const savedContent = ref('');
const isProcessing = ref(false);
const statusMessage = ref('');
const isSuccess = ref(true);

const textareaRef = ref<HTMLTextAreaElement | null>(null);
const textareaHeight = ref('auto');

const textareaStyle = computed(() => ({
    height: textareaHeight.value,
    overflow: 'hidden',
}));

const hasChanges = computed(() => {
    return localTextInput.value !== savedContent.value;
});

const adjustHeight = () => {
    const height = 300;
    if (textareaRef.value) {
        textareaRef.value.style.height = `${height}px`;
        textareaHeight.value = `${height}px`;
    }
};

onMounted(() => {
    localTextInput.value = props.element.content || '';
    savedContent.value = props.element.content || '';
    nextTick(() => {
        adjustHeight();
    });
});

const onEditIconClick = () => {
    emit('update:isEditMode', true);
    emit('update:canEdit', true);
    nextTick(() => {
        adjustHeight();
    });
};

const deleteReconciliationMutation = useDeleteReconciliationMutation()

const onDeleteIconClick = () => {
    modal.show('confirm', {
        title: 'Are you sure you want to delete this item?',
        onConfirm: async () => {
            isProcessing.value = true;
            try {
                await deleteReconciliationMutation.mutateAsync({
                    reconciliation_slug: props.reconciliationSlug,
                    reconciliation_update_slug: props.element.slug,
                });
                emit('update:element', null); // Remove the element from the UI
                isSuccess.value = true;
                statusMessage.value = 'Item deleted successfully.';
            } catch (error) {
                console.error('Error deleting reconciliation:', error);
                isSuccess.value = false;
                statusMessage.value = 'Error deleting item. Please try again.';
            } finally {
                isProcessing.value = false;
                setTimeout(() => {
                    statusMessage.value = '';
                }, 3000);
            }
        }
    });
};

const onTextAreaClick = () => {
    if (!props.isEditMode) {
        emit('update:isEditMode', true);
    }
};

const onInput = (value: string) => {
    localTextInput.value = value;
    nextTick(() => {
        adjustHeight();
    });
};

const cancelEdit = () => {
    localTextInput.value = savedContent.value;
    emit('update:canEdit', false);
};

const onLimitReached = () => {
    console.log('Limit reached');
};

const extractData = (value: string) => {
    console.log('Data extracted:', value);
};

const updateReconciliationMutation = useUpdateReconciliationMutation();

const saveChanges = async () => {
    if (!hasChanges.value) return;

    isProcessing.value = true;
    statusMessage.value = '';

    try {
        const response = await updateReconciliationMutation.mutateAsync({
            reconciliation_slug: props.reconciliationSlug,
            reconciliation_update_slug: props.element.slug,
            data: { content: localTextInput.value }
        });

        emit('update:element', { ...props.element, content: localTextInput.value });

        savedContent.value = localTextInput.value;

        isSuccess.value = true;
        statusMessage.value = 'Changes saved successfully';
    } catch (error) {
        console.error('Error saving changes:', error);
        isSuccess.value = false;
        statusMessage.value = 'Error saving changes. Please try again.';
    } finally {
        isProcessing.value = false;
        setTimeout(() => {
            statusMessage.value = '';
        }, 3000);
    }
};

watch(
    () => props.isEditMode,
    (newValue) => {
        if (newValue) {
            nextTick(() => adjustHeight());
        }
    }
);

watch(() => props.element, (newElement) => {
    if (newElement.content !== undefined) {
        localTextInput.value = newElement.content;
        savedContent.value = newElement.content;
        nextTick(() => adjustHeight());
    }
}, { deep: true });
</script>
