<!-- DynamicListView.vue -->
<template>
    <page-navigation-layout
        class="bg-white"
        fixed
        no-back-text
        transparent
    >
        <div class="flex w-full flex-col items-center px-2 py-10 md:py-16">
            <lists-hero-section
                v-if="heroData"
                :data="heroData"
                detailed-info
            />
            <list-wrapper
                :is-condensed="isCondensed"
                :search-value="searchValue"
                :title="title"
                enable-filter
                placeholder="Enter keyword"
                @update:search-value="updateSearch"
                @update:is-condensed="isCondensed = $event"
            >
                <template #modal>
                    <list-filter
                        v-model:selected-options="selectedOptions"
                        :get-item-progress="getProgressCategory"
                        :items="items"
                        show-readiness-filter
                    />
                </template>
                <template #closeButton1>
                    <button
                        class="text-medium mt-6 w-full rounded-full bg-black py-4 text-center text-base text-stone-50 hover:opacity-80"
                        @click="applyFilters"
                    >
                        Show {{ filteredItems.length }} results
                    </button>
                </template>
                <template #closeButton2>
                    <button
                        class="text-medium mx-auto -mt-3 text-center text-base text-black"
                        @click="resetFilters"
                    >
                        Reset
                    </button>
                </template>

                <template #content>
                    <div class="flex w-full max-w-prose flex-col gap-4">
                        <h6 class="text-sm font-semibold text-stone-500">
                            Total: {{ filteredItems.length }}
                        </h6>

                        <narrative-element-card
                            v-for="item in filteredItems"
                            :key="item?.id"
                            :element="item || {}"
                            :is-condensed="isCondensed"
                        />
                    </div>
                </template>
            </list-wrapper>
        </div>
    </page-navigation-layout>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import ListFilter from '@/shared/components/ListFilter.vue'
import ListWrapper from '@/shared/components/ListWrapper.vue'
import ListsHeroSection from '@/shared/components/ListsHeroSection.vue'
import PageNavigationLayout from '@/shared/components/PageNavigationLayout.vue'
import NarrativeElementCard from '@/shared/components/cards/NarrativeElementCard.vue'
import { getElementList } from '@/shared/utils/endpoints'

const props = defineProps<{
    path: string
    developmentDate?: string
}>()

const route = useRoute()
const items = ref([])
const loading = ref(true)
const searchValue = ref('')
const selectedOptions = ref(['all'])
const isCondensed = ref(true)
const title = ref('')
const heroData = ref(null)

const fetchItems = async () => {
    loading.value = true
    try {
        const response = await getElementList(props.path, {
            development_date: props.developmentDate,
        })
        items.value = response.data
        title.value = response.meta?.title || 'Story Elements'
        heroData.value = response.meta?.heroData || null
    } catch (error) {
        console.error('Error fetching items:', error)
    } finally {
        loading.value = false
    }
}

const filteredItems = computed(() => {
    return items.value.filter((item) => {
        const matchesSearch = item.title
            .toLowerCase()
            .includes(searchValue.value.toLowerCase())
        const matchesFilter =
            selectedOptions.value.includes('all') ||
            selectedOptions.value.includes(
                getProgressCategory(item.progress).toString()
            )
        return matchesSearch && matchesFilter
    })
})

function getProgressCategory(progress: number): string {
    if (progress > 90) return '1'
    if (progress > 60) return '2'
    if (progress > 30) return '3'
    return '4'
}

function resetFilters() {
    selectedOptions.value = ['all']
    searchValue.value = ''
    fetchItems()
}

function applyFilters() {
    // This function can be used to apply filters if needed
    // For now, it's just closing the modal
}

function updateSearch(value: string) {
    searchValue.value = value
    fetchItems()
}

onMounted(fetchItems)

watch(() => [props.path, props.developmentDate], fetchItems)
</script>
