<!-- @/shared/components/dynamic/blocks/SentimentContentBlock.vue -->
<template>
    <div class="flex w-full flex-col gap-4 py-2 md:gap-5">
        <!-- Positive Paragraphs -->
        <v-button
            v-if="editMode?.active"
            class="self-start"
            @click.stop="emit('connect-summary', 1)"
        >
            Connect summary
        </v-button>

        <div
            v-if="positiveParagraphs.length"
            class="relative flex w-full flex-col gap-2"
        >
            <summary-block
                is="div"
                v-for="paragraph in positiveParagraphs"
                :key="paragraph.id"
                :user-summary-content="paragraph.text"
                :user-summary-id="paragraph.id"
                class="flex w-full flex-row gap-2 rounded-md bg-blue-50 p-4"
                hover-class="hover:bg-blue-200"
            >
                <thumbs-icon
                    class="h-6 w-6 flex-shrink-0 text-blue-800"
                ></thumbs-icon>
                <p>{{ paragraph.text }}</p>
                <button
                    v-if="editMode?.active"
                    class="absolute right-0 top-0 rounded-full bg-red-500 p-1 text-white"
                    @click.stop="emit('delete', paragraph.id)"
                >
                    <trash-icon class="h-4 w-4" />
                </button>
            </summary-block>
        </div>

        <!-- Negative Paragraphs -->
        <div
            v-if="negativeParagraphs.length"
            class="relative flex w-full flex-col gap-2"
        >
            <summary-block
                is="div"
                v-for="paragraph in negativeParagraphs"
                :key="paragraph.id"
                :user-summary-content="paragraph.text"
                :user-summary-id="paragraph.id"
                class="flex w-full flex-row gap-2 rounded-md bg-red-50 p-4"
                hover-class="hover:bg-red-200"
            >
                <thumbs-icon
                    class="h-6 w-6 flex-shrink-0 rotate-180 text-red-800"
                ></thumbs-icon>
                <p>{{ paragraph.text }}</p>
                <button
                    v-if="editMode?.active"
                    class="absolute right-0 top-0 rounded-full bg-red-500 p-1 text-white"
                    @click.stop="emit('delete', paragraph.id)"
                >
                    <trash-icon class="h-4 w-4" />
                </button>
            </summary-block>
        </div>

        <div
            v-if="positiveLists.length || negativeLists.length"
            class="flex w-full flex-row gap-4"
        >
            <!-- Positive Lists -->
            <div
                v-if="positiveLists.length"
                class="flex w-1/2 flex-col gap-2"
            >
                <div
                    v-for="list in positiveLists"
                    :key="list.id"
                    class="relative"
                >
                    <ul class="flex flex-col gap-2 text-blue-700">
                        <summary-block
                            is="li"
                            v-for="item in list.list"
                            :key="item"
                            :user-summary-content="list.content"
                            :user-summary-id="list.id"
                            class="flex flex-row items-start gap-2 !rounded-full bg-blue-50 p-3 text-blue-700"
                            hover-class="hover:bg-blue-200"
                        >
                            <thumbs-icon
                                class="h-6 w-6 flex-shrink-0 text-blue-400"
                            ></thumbs-icon>
                            <div>{{ item }}</div>
                        </summary-block>
                    </ul>
                    <button
                        v-if="editMode?.active"
                        class="absolute right-0 top-0 rounded-full bg-red-500 p-1 text-white"
                        @click.stop="emit('delete', list.id)"
                    >
                        <trash-icon class="h-4 w-4" />
                    </button>
                </div>
            </div>
            <!-- Negative Lists -->
            <div
                v-if="negativeLists.length"
                class="flex w-1/2 flex-col gap-2"
            >
                <div
                    v-for="list in negativeLists"
                    :key="list.id"
                    class="relative"
                >
                    <ul class="flex flex-col gap-2 text-red-700">
                        <summary-block
                            is="li"
                            v-for="item in list.list"
                            :key="item"
                            :user-summary-content="list.content"
                            :user-summary-id="list.id"
                            class="flex flex-row items-start gap-2 !rounded-full bg-red-50 p-3 text-red-800"
                            hover-class="hover:bg-red-200"
                        >
                            <thumbs-icon
                                class="h-6 w-6 flex-shrink-0 rotate-180 text-red-600"
                            ></thumbs-icon>
                            <div>{{ item }}</div>
                        </summary-block>
                    </ul>
                    <button
                        v-if="editMode?.active"
                        class="absolute right-0 top-0 rounded-full bg-red-500 p-1 text-white"
                        @click.stop="emit('delete', list.id)"
                    >
                        <trash-icon class="h-4 w-4" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue'
import { SummaryComponentSummary } from '@/shared/types/page'
import ThumbsIcon from '@/shared/components/icons/ThumbsIcon.vue'
import { editModeInjection } from '@/shared/types/injection'
import TrashIcon from '@/shared/components/icons/TrashIcon.vue'
import VButton from '@/shared/components/VButton.vue'
import SummaryBlock from '@/shared/components/SummaryBlock.vue'

const emit = defineEmits(['connect-summary', 'delete'])

const props = defineProps({
    summaries: {
        type: Array<SummaryComponentSummary>,
        default: () => [],
    },
})

const editMode = inject(editModeInjection)

const positiveParagraphs = computed(() =>
    props.summaries.filter((summary) => summary.type === 'positive_paragraph')
)
const negativeParagraphs = computed(() =>
    props.summaries.filter((summary) => summary.type === 'negative_paragraph')
)
const positiveLists = computed(() =>
    props.summaries.filter((summary) => summary.type === 'positive_list')
)
const negativeLists = computed(() =>
    props.summaries.filter((summary) => summary.type === 'negative_list')
)
</script>

<style scoped></style>
