<!-- /components/ui/DetailLevelSwitcher.vue -->
<template>
    <div
        :class="
            modelValue
                ? 'bg-stone-50 text-stone-400'
                : 'bg-stone-400 text-stone-50'
        "
        class="flex aspect-square flex-shrink-0 cursor-pointer items-center justify-center rounded hover:bg-stone-400 hover:text-stone-50"
        @click="toggleView"
        title="Toggle expand or contract view"
    >
        <expand-contract-icon class="h-5 w-5" />
    </div>
    <!--    <div class="switcher flex items-center"-->
    <!--         @click="toggleView"-->
    <!--    >-->
    <!--        <span class="mr-2 select-none text-2xs">{{ getStateTitle }}</span>-->
    <!--        <label class="switch">-->
    <!--            <input type="checkbox"-->
    <!--                   :checked="modelValue"-->
    <!--                   @change="toggleView"-->
    <!--            />-->
    <!--            <span class="slider round"></span>-->
    <!--        </label>-->
    <!--    </div>-->
</template>

<script setup lang="ts">
import { computed } from 'vue'
import ExpandContractIcon from '@/shared/components/icons/ExpandContractIcon.vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps<{
    modelValue: boolean
}>()

const getStateTitle = computed(() =>
    props.modelValue ? 'Condensed' : 'Expanded'
)

const toggleView = () => {
    emit('update:modelValue', !props.modelValue)
}
</script>

<style scoped>
.switcher {
    display: flex;
    align-items: center;
    font-family: Arial, sans-serif;
}

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 20px;
}

.slider:before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #3b82f6;
}

input:checked + .slider:before {
    transform: translateX(14px);
}
</style>
