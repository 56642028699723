<template>
    <div class="flex w-full flex-col gap-2">
        <v-button
            v-if="editMode?.active"
            class="self-start"
            @click="emit('connect-summary', 1)"
        >
            Connect first summary
        </v-button>
        <template
            v-for="(summary, index) in summaries"
            :key="summary.summary_schema_slug"
        >
            <div class="relative">
                <subtitle-section
                    :description="summary.text"
                    :dialogue-lines="summary.dialogueLines"
                    :list="summary.list"
                    :title="summary.title === '' ? null : summary.title"
                    :user-summary-id="summary.id"
                />
                <button
                    v-if="editMode?.active"
                    class="absolute right-0 top-0 rounded-full bg-red-500 p-1 text-white"
                    @click="emit('delete', summary.id)"
                >
                    <trash-icon class="h-4 w-4" />
                </button>
            </div>
            <v-button
                v-if="editMode?.active"
                class="self-start"
                @click="emit('connect-summary', index + 2)"
            >
                Connect summary here
            </v-button>
        </template>
    </div>
</template>

<script lang="ts" setup>
import { SummaryComponentSummary } from '@/shared/types/page'
import SubtitleSection from '@/shared/components/cards/SubtitleSection.vue'
import VButton from '@/shared/components/VButton.vue'
import { inject } from 'vue'
import { editModeInjection } from '@/shared/types/injection'
import TrashIcon from '@/shared/components/icons/TrashIcon.vue'

const emit = defineEmits(['connect-summary', 'delete'])

const props = defineProps({
    summaries: {
        type: Array<SummaryComponentSummary>,
        default: () => [],
    },
})

const editMode = inject(editModeInjection)
</script>

<style scoped></style>
