<template>
    <section
        class="titleSection flex w-full flex-col items-start gap-3 text-left"
    >
        <slot name="title">
            <h4
                v-if="getTitle"
                :class="titleClass"
                class="select-none"
            >
                {{ getTitle }}
            </h4>
        </slot>
        <slot />
    </section>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const props = defineProps({
    title: {
        type: String,
        default: () => undefined,
    },
    titleClass: {
        type: String,
        default: () => undefined,
    },
    variant: {
        type: String,
        default: 'default',
    },
})

const getTitle = computed(() => {
    if (props.title && props.title !== 'Default') {
        return props.title
    }
    return undefined
})

const titleClass = computed(() => {
    if (props.titleClass) {
        return props.titleClass
    }

    switch (props.variant) {
        case 'muted':
            return 'text-sm font-semibold text-neutral-300'
        case 'lg':
            return 'text-lg md:text-2xl text-stone-950 font-bold'
        default:
            return 'text-lg md:text-2xl text-stone-950 font-bold'
    }
})
</script>
