<template>
    <svg
        class="shrink-0"
        xmlns="http://www.w3.org/2000/svg"
        width="4"
        height="4"
        viewBox="0 0 4 5"
        fill="none"
    >
        <circle
            cx="2"
            cy="2.5"
            r="2"
            fill="currentColor"
        />
    </svg>
</template>
