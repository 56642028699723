<!-- Description: This component is used to display a summary block. -->
<!-- Path: resources/shared/components/SummaryBlock.vue -->
<template>
    <component
        :is="is"
        :class="[dark ? 'hover:bg-stone-800' : hoverClass]"
        class="cursor-pointer rounded-xl p-1 duration-300"
        @click.exact="
            userSummaryId
                ? modal.show('full-screen-summary', {
                      userSummaryId: userSummaryId,
                      userSummaryContent: userSummaryContent,
                      projectSlug: projectSlug,
                  })
                : null
        "
    >
        <slot>
            {{ userSummaryContent }}
        </slot>
    </component>
</template>

<script lang="ts" setup>
import useModal from '@/shared/composables/useModal'
import type { UserSummary } from '@/shared/types/userSummary'
import { inject } from 'vue'

const props = defineProps({
    userSummaryId: {
        type: Number,
        required: () => undefined,
    },
    userSummaryContent: {
        type: String,
        required: true,
    },
    summary: {
        type: Object as () => UserSummary,
        default: null,
    },
    is: {
        type: String,
        default: 'p',
    },
    dark: {
        type: Boolean,
        default: false,
    },
    hoverClass: {
        type: String,
        default: 'hover:bg-stone-200',
    },
})

const projectSlug = inject<string | undefined>('project_slug')

const modal = useModal()
</script>

<style scoped></style>
