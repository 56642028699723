<template>
    <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_1465_7233)">
            <path
                d="M12.5 19.5495C11.1318 18.7596 9.57983 18.3437 8 18.3437C6.42017 18.3437 4.86817 18.7596 3.5 19.5495V6.54952C4.86817 5.75961 6.42017 5.34375 8 5.34375C9.57983 5.34375 11.1318 5.75961 12.5 6.54952M12.5 19.5495V6.54952M12.5 19.5495C13.4312 19.0119 14.4476 18.6475 15.5 18.4696M12.5 6.54952C13.8682 5.75961 15.4202 5.34375 17 5.34375C18.5798 5.34375 20.1318 5.75961 21.5 6.54952V11.3496"
                stroke="#A7A29E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M20.2998 13.75V16.75M20.2998 16.75V19.75M20.2998 16.75H23.2998M20.2998 16.75H17.2998"
                stroke="#A7A29E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_1465_7233">
                <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0.5 0.549805)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
