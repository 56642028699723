<template>
    <section
        class="flex flex-col justify-center self-stretch border-b border-solid border-b-stone-300 bg-white p-3 text-base"
    >
        <div class="flex w-full items-center justify-between">
            <div
                class="my-auto flex flex-1 shrink basis-12 flex-col self-stretch"
            >
                <h2 class="font-medium text-stone-700">Reconciliations Available</h2>
                <p class="font-bold text-stone-900">
                    {{ availableReconciliations }} {{ getReconciliationAvailabilityTimeRestriction }}
                </p>
                <p v-if="nextResetTime && getReconciliationAvailabilityTimeRestriction !== ''"
                   class="text-sm text-stone-500">
                    Next reset: {{ formatNextResetTime }}
                </p>
            </div>
            <check-icon
                v-if="selectedReconciliation.completed_at !== null"
                class="h-8 w-8 mr-4 border-2 border-stone-600 text-stone-600 rounded-full p-1"
            />
            <div
                v-else-if="canReconcile"
                class="my-auto min-h-[48px] self-stretch rounded-xl px-6 py-4 text-center font-bold cursor-pointer select-none bg-stone-600 text-white hover:bg-stone-800"
                @click="reconcileNow"
            >
                Reconcile Now
            </div>
            <app-loader v-else-if="selectedReconciliation.started_at !== null" class="h-6 w-6 mr-4" />
        </div>
    </section>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useInitiateReconciliationMutation, useReconciliationStore } from '@/shared/stores/reconciliations'
import { useAuthStore } from '@/shared/stores/auth'
import { storeToRefs } from 'pinia'
import { useUser } from '@/shared/composables/query/user'
import AppLoader from '@/shared/components/AppLoader.vue'
import CheckIcon from '@/shared/components/icons/CheckIcon.vue'

const props = defineProps({
    selectedReconciliation: {
        type: Object,
        required: true,
    },
})

const reconciliationStore = useReconciliationStore()
const authStore = useAuthStore()
const { availableReconciliations, nextResetTime } = storeToRefs(reconciliationStore)

const { isLoading } = useUser()

const canReconcile = computed(() => availableReconciliations.value > 0 && !isLoading.value && props.selectedReconciliation.started_at === null)

const getReconciliationAvailabilityTimeRestriction = computed(() => {
    console.log('the authStore.user?.subscription is ', authStore.user?.subscription)
    if ((authStore.user?.subscription === 'Pro Membership') || (authStore.user?.subscription === 'Essentials Membership')) {
        return 'today'
    }
    return ''
})

const formatNextResetTime = computed(() => {
    if (!nextResetTime.value) return ''
    return new Date(nextResetTime.value).toLocaleString()
})

const initiateReconciliationMutation = useInitiateReconciliationMutation()

const reconcileNow = async () => {
    if (canReconcile.value && props.selectedReconciliation) {
        try {
            await initiateReconciliationMutation.mutateAsync(props.selectedReconciliation.slug)
            // Handle success (e.g., show a success message)
        } catch (error) {
            console.error('Failed to initiate reconciliation:', error)
            // Handle error (e.g., show an error message)
        }
    }
}
</script>
