<template>
    <router-link
        :to="computedPath"
        class="narrative-element-card w-full"
    >
        <div
            class="flex flex-col gap-2 rounded-lg border border-solid border-transparent p-3 hover:border-stone-200 hover:shadow"
            :class="getCardClasses"
        >
            <div
                class="narrative-element-card-header flex flex-row items-start gap-2 overflow-hidden"
            >
                <image-component
                    v-if="
                        (element.image &&
                            element.image?.small) ||
                        !element.display_order
                    "
                    :image="element.image"
                    size="thumbnail"
                    :alt="element.title"
                    class="h-11 w-11 flex-shrink-0 rounded object-cover"
                />
                <div
                    v-else-if="element.display_order"
                    class="flex h-8 w-8 flex-shrink-0 select-none items-center justify-center rounded bg-stone-400 object-cover text-xs text-white"
                >
                    {{ element.display_order }}
                </div>

                <div
                    class="flex w-full select-none flex-col gap-0 self-center overflow-hidden"
                >
                    <div
                        v-if="element.tags.length"
                        class="w-full items-center gap-1 truncate text-4xs font-bold uppercase"
                        :class="getTagsClasses"
                    >
                        {{ getTags }}
                    </div>

                    <h5
                        class="truncate font-bold"
                        :class="getTitleClass"
                        :title="element.title"
                    >
                        {{ element.title }}
                    </h5>

                    <div
                        v-if="element.subtitles?.length"
                        class="w-full truncate text-3xs font-medium text-stone-600"
                    >
                        {{ getSubtitles }}
                    </div>
                </div>

                <flame-icon
                    v-if="element.progress !== undefined"
                    :progress="element.progress"
                    class="!h-8 !w-8 flex-shrink-0"
                    flameClass="w-6 h-6"
                />
            </div>

            <div
                v-if="!isCondensed"
                class="narrative-element-card-content flex w-full flex-col gap-2"
            >
                <hr
                    v-if="showLine && element.paragraph"
                    class="w-full text-stone-500"
                />

                <p
                    v-if="element.paragraph"
                    class="select-none text-sm"
                    :class="getParagraphClasses"
                >
                    {{ element.paragraph }}
                </p>

                <div
                    v-if="element.additional_data"
                    class="additional-data"
                >
                    <!-- Render additional data based on element type -->
                    <template
                        v-if="
                            element.type === 'audience' &&
                            element.additional_data.prefer &&
                            element.additional_data.dislike
                        "
                    >
                        <p
                            class="mt-2 flex items-start gap-2 text-sm font-normal text-stone-800"
                        >
                            <thumbs-icon
                                class="h-6 w-6 shrink-0 rounded-full bg-white p-0.5 text-blue-500"
                            />
                            {{ element.additional_data.prefer }}
                        </p>
                        <p
                            class="mt-2 flex items-start gap-2 text-sm font-normal text-stone-800"
                        >
                            <thumbs-icon
                                class="h-6 min-w-6 shrink-0 rotate-180 rounded-full bg-white p-0.5 text-red-600"
                            />
                            {{ element.additional_data.dislike }}
                        </p>
                    </template>
                    <!-- Add more type-specific rendering as needed -->
                </div>
            </div>
        </div>
    </router-link>
</template>

<script setup lang="ts">
import { computed, onMounted, PropType } from 'vue'
import FlameIcon from '@/shared/components/FlameInProgressCircle.vue'
import ImageComponent from '@/shared/components/ImageComponent.vue'
import ThumbsIcon from '@/shared/components/icons/ThumbsIcon.vue'
import { useRoute } from 'vue-router'

interface NarrativeElement {
    type: string
    display_order?: number
    image?: string
    slug: string
    tags: string[]
    title: string
    titleClass?: string
    subtitles: string[]
    progress?: number
    paragraph?: string
    path: string
    additional_data?: Record<string, any>
}

const props = defineProps({
    element: {
        type: Object as PropType<NarrativeElement>,
        required: false,
    },
    showLine: {
        type: Boolean,
        default: true,
    },
    isCondensed: {
        type: Boolean,
        default: true,
    },
    selected: {
        type: Boolean,
        default: false,
    },
})

const route = useRoute()

// const isCondensed = computed(() => {
//     return props.setIsCondensed
// })

const getTags = computed(() => {
    return props.element.tags.join(' • ')
})

const getSubtitles = computed(() => {
    return props.element.subtitles.join(' • ')
})

const getTitleClass = computed(() => {
    const baseClasses = []
    const colorClass = props.selected ? 'text-stone-50' : 'text-stone-800'

    // Add the color class
    baseClasses.push(colorClass)

    // Check for element.titleClass
    if (props.element.titleClass) {
        // Split the titleClass string into an array of classes
        const titleClasses = props.element.titleClass.split(' ')

        // Filter out any color classes from element.titleClass
        const nonColorClasses = titleClasses.filter(cls => !cls.startsWith('text-'))

        // Add the non-color classes to baseClasses
        baseClasses.push(...nonColorClasses)
    } else {
        // If no titleClass, add classes based on element type
        if (['series', 'project', 'season', 'story'].includes(props.element.type)) {
            baseClasses.push('font-fjalla', 'text-lg')
        }
    }

    // Join all classes into a single string
    return baseClasses.join(' ')
})

const computedPath = computed(() => {
    const narrativePath = props.element.path

    if (
        route.path.startsWith('/projects') &&
        route.path.split('/').length > 2
    ) {
        // 1. If the current route path starts with /projects/project_slug, then add that in front of the narrative path
        const projectSlug = route.path.split('/')[2]
        return `/projects/${projectSlug}${narrativePath}`
    }

    // 2. Otherwise, just use the narrative path
    return `${narrativePath}`
})

const getCardClasses = computed(() => {
    if(props.selected) {
        return {
            'bg-stone-800': true,
        }
    }
    return {
        'bg-stone-100': true,
    }
})

const getTagsClasses = computed(() => {
    if(props.selected) {
        return {
            'text-stone-50': true,
        }
    }
    return {
        'text-stone-500': true,
    }
})

const getParagraphClasses = computed(() => {
    if(props.selected) {
        return {
            'text-stone-400': true,
        }
    }
    return {
        'text-stone-700': true,
    }
})

onMounted(() => {})
</script>

<style scoped>
/* Add any necessary styles */
</style>
