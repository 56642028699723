<template>
    <div
        class="flex flex-col items-center justify-center gap-5 backdrop-blur-sm"
        @click.self="$emit('close')"
    >
        <div
            class="py2 flex w-full max-w-prose flex-col gap-6 rounded-xl border bg-white px-2 text-lg shadow-xl md:px-4 md:py-4 lg:px-10 lg:py-8"
        >
            <title-section>
                <div
                    class="flex w-full justify-start gap-10 border-b border-solid border-stone-200 pb-2 text-xl"
                >
                    <button
                        :class="{ 'font-bold': activeTab === 'current' }"
                        @click="activeTab = 'current'"
                    >
                        Current
                    </button>
                    <button
                        :class="{
                            'font-bold': activeTab === 'previous',
                            'cursor-not-allowed': !hasPreviousVersions,
                        }"
                        :disabled="!hasPreviousVersions"
                        @click="activeTab = 'previous'"
                    >
                        Previous Versions ({{ previousVersionsCount }})
                    </button>
                </div>
                <div v-if="activeTab === 'current'">
                    <p class="">
                        {{ userSummaryContent }}
                    </p>
                </div>
                <div
                    v-else
                    class="max-h-96 overflow-y-auto"
                >
                    <div
                        v-for="version in previousVersions"
                        :key="version.id"
                        class="mb-4 border-b pb-4"
                    >
                        <p class="mb-2">{{ version.summary }}</p>
                        <p class="text-sm text-gray-500">
                            {{ formatDate(version.created_at) }}
                        </p>
                    </div>
                </div>
            </title-section>

            <div
                v-if="projectSlug"
                class="flex flex-col items-center"
            >
                <app-loader v-if="loadingUserSummary" class="h-10 w-10"/>
                <template v-else-if="!reconciliationUpdate">
                    <div v-if="isSpeakingMode || loading">
                        <voice-button
                            :loading="loading"
                            variant="small"
                            @recorded="processUpdate"
                        />
                    </div>
                    <div v-else>
                        <textarea
                            v-model="updateInput"
                            class="w-full rounded border p-2"
                            placeholder="Enter your update..."
                        ></textarea>
                        <v-button @click="processUpdate(updateInput)">
                            Submit
                        </v-button>
                    </div>
                    <button
                        class="mt-2 text-sm text-gray-500"
                        @click="isSpeakingMode = !isSpeakingMode"
                    >
                        Switch to
                        {{ isSpeakingMode ? 'typing' : 'speaking' }} mode
                    </button>
                </template>
                <template v-else>
                    <check-icon class="h-10 w-10 bg-green-500 text-white rounded-full"></check-icon>
                    <small class="text-sm max-w-[300px] text-center mt-5">
                        Your update is saved, you can find it in the reconciliations list.
                    </small>
                </template>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import {
    getSummaryHistory,
    processSummaryUpdate,
} from '@/shared/utils/endpoints'
import TitleSection from '@/shared/components/TitleSection.vue'
import VButton from '@/shared/components/VButton.vue'
import VoiceButton from '@/shared/components/VoiceButton.vue'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import useChannel from '@/shared/composables/useChannel'
import { useAuthStore } from '@/shared/stores/auth'
import CheckIcon from '@/shared/components/icons/CheckIcon.vue'
import AppLoader from '@/shared/components/AppLoader.vue'

const props = defineProps({
    userSummaryId: {
        type: Number,
        required: true,
    },
    userSummaryContent: {
        type: String,
        required: true,
    },
    projectSlug: {
        type: [Number, String],
        default: () => undefined,
    },
})

dayjs.extend(relativeTime)

const activeTab = ref('current')
const previousVersions = ref([])
const isUpdating = ref(false)
const isSpeakingMode = ref(true)
const loading = ref(false)
const updateInput = ref('')
const updateIdentifier = ref('')
const { user } = useAuthStore()

const previousVersionsCount = computed(() => previousVersions.value.length)
const hasPreviousVersions = computed(() => previousVersions.value.length > 0)
const loadingUserSummary = ref(true)
const reconciliationUpdate = ref(false)

async function fetchSummaryHistory() {
    try {
        const response = await getSummaryHistory(props.userSummaryId)
        previousVersions.value = response.history
        reconciliationUpdate.value = !!response.reconciliation_update
        loadingUserSummary.value = false
    } catch (error) {
        console.error('Failed to fetch summary history:', error)
    }
}

onMounted(fetchSummaryHistory)

async function startUpdate() {
    isUpdating.value = true
    // try {
    //     const response = await initiateSummaryUpdate(props.userSummaryId)
    //     updateIdentifier.value = response.identifier
    // } catch (error) {
    //     console.error('Failed to initiate summary update:', error)
    //     isUpdating.value = false
    // }
}

async function processUpdate(input: string | Blob) {
    if (!props.projectSlug) return
    loading.value = true
    try {
        await processSummaryUpdate(
            props.projectSlug,
            props.userSummaryId,
            input
        )
        // loading.value = false
        // isUpdating.value = false
        // fetchSummaryHistory() // Re-fetch the history after update
    } catch (error) {
        console.error('Failed to process summary update:', error)
        loading.value = false
    }
}

function formatDate(date) {
    const now = dayjs()
    const createdAt = dayjs(date)
    return now.diff(createdAt, 'day') <= 1
        ? createdAt.fromNow()
        : createdAt.format('MMM D, YYYY')
}

const { listen } = useChannel()

onMounted(() => {
    listen('private', `App.Models.User.${user!.id}`)?.listen(
        '.reconciliation-update.processed',
        () => {
            console.log('completed')
            loading.value = false
            isUpdating.value = false
            loadingUserSummary.value = false
            reconciliationUpdate.value = true
        }
    )
})
</script>

<style scoped></style>
