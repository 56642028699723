<template>
    <narrative-element-card
        :element="story"
        :is-condensed="isCondensed"
    />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import NarrativeElementCard from '@/shared/components/cards/NarrativeElementCard.vue'

const props = defineProps({
    element: {
        type: Object,
        required: true,
    },
    isCondensed: {
        type: Boolean,
        default: true,
    },
})

const story = computed(() => {
    return {
        type: props.element.type || 'Story',
        slug: props.element.project_slug,
        title: props.element.name,
        titleClass: 'font-fjalla text-lg',
        paragraph: props.element.description,
        image: props.element.image,
        tags: props.element.genres || [],
        subtitles: [props.element.format].filter(Boolean),
        progress: props.element.progress,
        path: props.element.path,
    }
})
</script>

<style scoped></style>
