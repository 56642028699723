<template>
    <div class="flex flex-row w-full gap-4 items-center justify-start px-2 xl:px-0 select-none">
        <slot>
            <h4
                class="w-full"
                :class="titleClass"
            >{{ title }}</h4>
        </slot>

<!--        <button-->
<!--            class="whitespace-nowrap"-->
<!--            :class="buttonClass"-->
<!--            @click="handleClick"-->
<!--        >-->
<!--            {{ buttonText }}-->
<!--        </button>-->
    </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router'

const emit = defineEmits(['see-all'])

const props = defineProps({
    title: {
        type: String,
        default: () => undefined,
    },
    titleClass: {
        type: String,
        default: '',
    },
    buttonText: {
        type: String,
        default: 'See all',
    },
    buttonClass: {
        type: String,
        default: 'font-normal text-slate-400 hover:text-slate-700'
    },
    to: {
        type: [String, Object],
        default: () => undefined,
    },
})

const router = useRouter()

function handleClick() {
    if (props.to) {
        router.push(props.to)
    } else {
        emit('see-all')
    }
}
</script>
