<!-- @/App.vue -->
<template>
    <meta-tags
        :description="metaDescription"
        :image="metaImage"
        :title="metaTitle"
    />
    <router-view />
</template>

<script lang="ts" setup>
import {
    computed,
    h,
    onMounted,
    onUnmounted,
    provide,
    reactive,
    ref,
    watch,
} from 'vue'
import { uaInjectKey } from '@/shared/types/inject'
import { useAuthStore } from '@/shared/stores/auth'
import useModal from '@/shared/composables/useModal'
import { usePagesStore } from '@/shared/stores/pages'
import { useRoute, useRouter } from 'vue-router'
import useChannel from '@/shared/composables/useChannel'
import { getProjectElements } from '@/shared/utils/endpoints'
import { useProjectStore } from '@/shared/stores/project'
import MetaTags from '@/shared/components/MetaTags.vue'
import { useEcho } from '@/shared/types/useEcho'
import { editModeInjection } from '@/shared/types/injection'
import { toast } from 'vue3-toastify'
import AnnouncementModal from '@/shared/components/modals/AnnouncementModal.vue'
import { useLogger } from 'vue-logger-plugin'

const metaTitle = computed(() => {
    return (
        'Speak Your Story to Life | StoryFlame' || (route.meta.title as string)
    )
})
const metaDescription = computed(() => {
    return (
        'With innovative speech-to-story technology and an intuitive story-crafting interface, StoryFlame is your partner in transforming your story vision into a comprehensive outline.' ||
        (route.meta.description as string)
    )
})
const metaImage = computed(() => {
    return '/images/og-image.jpg' || (route.meta.image as string)
})

const authStore = useAuthStore()
const { show } = useModal()
const { transition, setTransition } = usePagesStore()

const router = useRouter()
const route = useRoute()
const logger = useLogger()

const currentProjectElements = ref(null)
const navHeight = ref(0)
const projectStore = useProjectStore()

provide(
    'sideMenuConfig',
    reactive({
        create: null,
        develop: null,
        ideate: null,
        update: null,
    })
)

const useFullWidth = ref(false)

provide('projectStore', projectStore)
provide(
    editModeInjection,
    ref({
        active: false,
        dummy: false,
    })
)

async function fetchProjectElements(slug: string, categoryModelPlural: string) {
    try {
        const elements = await getProjectElements(slug, categoryModelPlural)
        currentProjectElements.value = elements
    } catch (error) {
        console.error('Failed to fetch project elements:', error)
        throw error
    }
}

provide('useFullWidth', useFullWidth)

// router.beforeEach((to, from, next) => {
//     if (from.name === undefined && from.path === '/') setTransition('none')
//     else if (!transition.name.endsWith('-back')) {
//         if (to.meta.transition) {
//             setTransition(to.meta.transition)
//         } else {
//             setTransition('default')
//         }
//     }
//     next()
// })

const { listen, leave, leaveAll } = useChannel()
const echo = useEcho()

const ua = reactive({
    agent: '',
    isMobile: false,
})

provide(uaInjectKey, ua)

const initializeApp = async () => {
    ua.agent = navigator.userAgent
    ua.isMobile = /Mobi/.test(ua.agent)

    // Calculate the height of the fixed elements
    const freeTrialBanner = document.getElementById('free-trial-banner')
    const mainNav = document.getElementById('main-nav')
    let totalHeight = 0
    if (freeTrialBanner) totalHeight += freeTrialBanner.offsetHeight
    if (mainNav) totalHeight += mainNav.offsetHeight

    navHeight.value = totalHeight
}

watch(
    () => authStore.isLoggedIn,
    (newValue) => {
        if (
            !newValue &&
            !['login', 'magic-link'].includes(route.name as string)
        ) {
            router.push({ name: 'login' })
        }
    }
)

watch(
    () => authStore.user,
    (value, oldValue) => {
        logger.debug('User changed:', value)
        if (value && !oldValue) {
            logger.debug('User logged in, starting listening')
            echo.reinit()
            listen('private', `App.Models.User.${authStore.user.id}`)
                ?.listen('.achievement.unlocked', (e) => {
                    toast(`<b>Achievement unlocked</b>\n${e.title}`, {
                        icon: () =>
                            h('img', {
                                src: '/storage/' + e.icon,
                                alt: e.title,
                                class: 'w-8 h-8',
                            }),
                    })
                })
                .listen('.announcement', (e) => {
                    // todo: @mitch can change this to full screen modal because popup looks bad?
                    show(AnnouncementModal, {
                        type: 'full',
                        announcement_type: e.type,
                        title: e.title,
                        subtitle: e.subtitle,
                        content: e.content,
                    })
                })
                .listen('.elements.created', (e) => {
                    e.elements.forEach((element) => {
                        toast(`${element.count} new ${element.label} created`)
                    })
                })
            // Add any logic for when a user logs in
            logger.debug('Listening for unlocked achievements')
        } else if (!value && oldValue) {
            logger.debug('User logged out, stopping listening')
            // Add any logic for when a user logs out
            leaveAll()
        }
    },
    { deep: true, immediate: true }
)

onUnmounted(async () => {
    await authStore.initialize()
    await initializeApp()
    if (authStore.isLoggedIn && route.name === 'login') {
        void router.push({ name: 'home' })
    }
    leaveAll()
})
</script>
