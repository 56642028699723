<template>
    <img
        v-if="imageSrc && !loading"
        :alt="alt"
        :src="imageSrc"
        class="cursor-pointer select-none bg-cover bg-center bg-no-repeat object-cover hover:scale-101 hover:shadow-lg"
        @click="emit('upload')"
    />
    <div
        v-else
        :class="{
            'animate-pulse': loading,
        }"
        class="flex cursor-pointer select-none items-center justify-center bg-stone-300 hover:bg-stone-200"
        @click="emit('upload')"
    >
        <no-image v-if="!loading" class="w-6 text-stone-400" />
    </div>
</template>

<script lang="ts" setup>
import { computed, inject } from 'vue'
import NoImage from '@/shared/components/icons/NoImage.vue'

const emit = defineEmits(['upload'])

const props = defineProps({
    loading: {
        type: Boolean,
        default: false,
    },
    image: {
        type: Object,
        default: null,
    },
    src: {
        type: String,
        default: null,
    },
    alt: {
        type: String,
        default: 'image',
    },
    context: {
        type: Object,
        default: () => undefined,
    },
    disableClick: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: 'medium',
        validator: (value: string) =>
            ['thumbnail', 'small', 'medium', 'large', 'original'].includes(
                value
            ),
    },
})

const openImagePopup = inject('openImagePopup')

const imageSrc = computed(() => {
    if (props.src) return props.src
    if (props.image) {
        return (
            props.image[props.size] ||
            props.image.medium ||
            props.image.original
        )
    }
    return null
})

const handleClick = () => {
    if (props.disableClick) return
    if (openImagePopup) {
        openImagePopup(props.image, props.context)
    }
}
</script>
