<template>
    <div
        class="flex flex-col items-center justify-center gap-5 backdrop-blur-sm"
        @click.self="$emit('close')"
    >
        <div
            class="py2 flex w-full max-w-prose flex-col gap-6 rounded-xl border bg-white px-2 text-lg shadow-xl md:px-4 md:py-4 lg:px-10 lg:py-8"
        >
            <div class="flex flex-col items-center gap-2 text-center">
                <h1 class="text-2xl text-stone-800">
                    {{ title }}
                </h1>
                <p class="text-sm text-stone-500">
                    {{ subtitle }}
                </p>

                <div
                    v-if="content"
                    class="mt-2 text-lg text-stone-600"
                    v-html="md.render(content)"
                ></div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import MarkdownIt from 'markdown-it'
import MarkdownItVideo from 'markdown-it-video'

const emit = defineEmits(['close'])

const props = defineProps({
    announcement_type: String,
    title: String,
    subtitle: String,
    content: String,
})

const md = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
}).use(MarkdownItVideo, {
    containerClassName: 'video-embed',
})
</script>

<style scoped></style>
