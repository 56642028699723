import { Component, inject } from 'vue'
import { ModalEventCallback, modalInjectKey } from '@/shared/plugins/modalPlugin'

export default function useModal() {
    const modalInjection = inject(modalInjectKey)

    return {
        show: (
            component: Component | string,
            props: Record<string, any> = {},
            events: { [key: string]: ModalEventCallback } = {}
        ): string => {
            return modalInjection?.show(component, props, events)
        },

        close: (id: string | undefined = undefined): void => {
            modalInjection?.close(id)
        },
    }
}
